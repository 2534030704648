import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import useStyles from './styles';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

const StatisticsCard = (props) => {
    const { icon, value, title } = props;

    const classes = useStyles();

    return (
        <Grid container direction="column" justify="center" className={classes.root}>
            <div className={classes.iconWrapper}>
                <FontAwesomeIcon icon={['fad', icon]} fixedWidth className={classes.icon} />
            </div>
            <Typography variant="h4" component="div" align="center">
                {Number.isNaN(Number(value)) ? value : Number(value).toLocaleString('en-US')}
            </Typography>
            <Typography variant="h6" component="div" align="center">
                {title}
            </Typography>
        </Grid>
    );
};

StatisticsCard.propTypes = {
    icon: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
};

export default StatisticsCard;
